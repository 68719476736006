import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Shell } from '@components/Shell/Shell';
import nl2br from 'react-nl2br';
import { useWhichLanguage } from '@src/hooks/useWhichLanguage';
import { SEO } from '@src/components/SEO/SEO';
import { Content } from '@src/components/Content/Content';
import { Heading } from '@src/components/Heading/Heading';

const AboutPage: React.FC<
  PageProps & {
    data: {
      api: {
        about: {
          id: number;
          svText: string;
          enText: string;
          enHtmlTitle: string;
          svHtmlTitle: string;
          enHtmlDescription: string;
          svHtmlDescription: string;
          imagePath: string;
          imagePathSharp: {
            childImageSharp: { gatsbyImageData: IGatsbyImageData };
          };
        } | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { about },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { isEnglish } = useWhichLanguage();
  const { t } = useI18next();

  if (!about) {
    return null;
  }

  const {
    imagePathSharp,
    svText,
    enText,
    enHtmlTitle,
    svHtmlTitle,
    enHtmlDescription,
    svHtmlDescription,
  } = about;

  const image = getImage(imagePathSharp);

  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        openGraphImagePath={
          imagePathSharp.childImageSharp.gatsbyImageData.images.fallback?.src
        }
        openGraphImageWidth={
          imagePathSharp.childImageSharp.gatsbyImageData.width
        }
        openGraphImageHeight={
          imagePathSharp.childImageSharp.gatsbyImageData.height
        }
        title={isEnglish ? enHtmlTitle : svHtmlTitle}
        description={isEnglish ? enHtmlDescription : svHtmlDescription}
      />
      <Content>
        <Container>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              spacing={4}
              sm={12}
              md={9}
              item
              justifyContent="space-between"
            >
              <Grid item sm={7}>
                <Heading className="mt0">{t('About')}</Heading>
                <p className="mt0">{nl2br(isEnglish ? enText : svText)}</p>
              </Grid>
              {image && (
                <Grid item sm={5}>
                  <GatsbyImage
                    image={image}
                    alt=""
                    style={{ borderRadius: '50%' }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </Content>
    </Shell>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    api {
      about {
        id
        imagePath
        imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              width: 600
              height: 600
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
        svText
        enText
        enHtmlTitle
        svHtmlTitle
        enHtmlDescription
        svHtmlDescription
      }
    }
  }
`;
